import { ROUTE_PRODUCT, ROUTE_SUBSCRIPTION } from '@constants';
import { Variant } from '@framework/api/types';
import Button from '@components/Button';
import * as BuyButtonStyles from '@components/PDP/BuyButtonGroup/styles';
import {
  SubscriptionProductCollection,
  SubscriptionProductItem,
  SubscriptionProductUpgradeOption,
} from 'fragile/types';
import Image from '@components/Image';
import Link from 'next/link';
import { FC, memo } from 'react';
import KeySpecs from '@components/PDP/KeySpecs/KeySpecs';
import { FpsCard } from '@components/PDP/KeySpecs/Cards';
import * as styles from './styles';

type Props = {
  name: string;
  slug: string;
  productPrice?: {
    isRecurring: boolean;
    recurringInterval: string;
    amount: number;
  };
  upgradeOptions?: SubscriptionProductUpgradeOption[];
  subscriptionProducts?: SubscriptionProductItem[];
  variants?: Variant[];
  specs: {
    [key: string]: string;
  };
  isSubscription: boolean;
  fragileProductCollection: SubscriptionProductCollection;
};

const LineupCard: FC<Props> = ({
  name,
  slug,
  productPrice,
  upgradeOptions,
  specs,
  fragileProductCollection,
  isSubscription,
}) => {
  const linkage = isSubscription
    ? `/${ROUTE_SUBSCRIPTION}/${slug}`
    : `/${ROUTE_PRODUCT}/${slug}`;

  const image =
    (isSubscription &&
      fragileProductCollection.fromCms.images[0]?.responsiveImage) ??
    null;
  const friendlyPriceString = `$${(productPrice.amount / 100).toFixed(0)}`;
  const friendlyOneLine = `${friendlyPriceString} per ${productPrice.recurringInterval.toLowerCase()}`;

  return (
    <div className={styles.cardWrapper}>
      <div className={styles.cardContainer}>
        <div className={styles.imageWrapper}>
          <Link href={linkage} passHref role="link" tabIndex={0}>
            <Image
              className={styles.image}
              data={image}
              width={image.width}
              height={image.height}
            />
          </Link>
        </div>

        <div className={styles.contentContainer}>
          <Link href={linkage} passHref role="link" tabIndex={0}>
            <div className={styles.growWrapper}>
              <div className={styles.titleText}>{name}</div>

              <div className={styles.priceContainer}>
                <p className={styles.priceTitle}>
                  {productPrice?.isRecurring
                    ? friendlyOneLine
                    : productPrice.amount}
                </p>
              </div>
            </div>
          </Link>

          <div className="hidden md:block text-sm">
            <div className={styles.specsList}>
              {Object.entries(specs).map(
                ([key, value]) =>
                  (key === 'CPU' || key === 'GPU') && (
                    <div key={key} className={styles.specsItem}>
                      {value}
                    </div>
                  )
              )}
            </div>
          </div>

          <div
            className={`${styles.contentContainerNoBleed} hidden md:block mb-2 mt-4`}
          >
            <div className="font-bold">FPS Test on Ultra High Settings:</div>

            <FpsCard
              fps={fragileProductCollection.fromCms.fps[0]}
              chipsetType={fragileProductCollection.fromCms.fps[0].chipsetType}
              noContentWrapper
              noBackground
              showText={false}
            />
          </div>

          <div
            className={`${styles.contentContainerNoBleed} md:hidden mb-4 mt-4`}
          >
            <KeySpecs
              fps={fragileProductCollection.fromCms.fps[0]}
              keySpecs={fragileProductCollection.fromCms.keySpecsCard ?? []}
              isSm
              isMd={false}
              isLg={false}
              isXl={false}
              fullBleed
              overrideHeading=""
              noFpsBackground
            />
          </div>
        </div>

        <div className={styles.contentContainer}>
          <div className={BuyButtonStyles.buttonWrapper(false)}>
            <Button
              buttonStyle="primary"
              dataTestId="add-to-cart-button"
              type="button"
              onClick={() => {
                window.location.href = linkage;
              }}
              disabled={upgradeOptions.some(
                option => option?.fromCms?.isOutOfStock
              )}
              customMargin
              className={`${BuyButtonStyles.cartButton} mt-6`}
            >
              Select
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(LineupCard);
