/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, memo, useState, useMemo } from 'react';
import { useMedia, useTranslation as t } from '@utils/hooks';
import { useRouter } from 'next/router';
import Button from '@components/Button';
import { getRegion } from '@framework/api/utils/maxify';
import type {
  PrebuiltCardsProps,
  PrebuildComparisonField,
  ComparisonProduct,
  DatoProduct,
} from '@framework/api/types';
import SectionWrapper from '@components/SectionWrapper';
import ContentWrapper from '@components/ContentWrapper';
import { MAXIFY_CURRENCY_MAP_PER_REGION } from '@constants';
import PrebuiltComparisonTableSm from './PrebuiltComparisonTableSm';
import PrebuiltComparisonTableLg from './PrebuiltComparisonTableLg';
import * as styles from './styles';

type Props = {
  prebuiltCards: PrebuiltCardsProps;
  comparisonProducts: DatoProduct[] | ComparisonProduct[];
  heading?: string;
  subheading?: string;
  enabledCardFields: PrebuildComparisonField[];
  chipsetType?: string;
};

// TODO - Pull information from DATO regarding product price/name
const PrebuiltComparisonTable: FC<Props> = ({
  prebuiltCards,
  heading,
  subheading,
  comparisonProducts,
  enabledCardFields,
  chipsetType,
}) => {
  const { locale } = useRouter();
  const region = getRegion(locale);
  const currencyCode = useMemo(
    () => MAXIFY_CURRENCY_MAP_PER_REGION[region],
    [region]
  );

  const [showMore, setShowMore] = useState(false);
  const isSm = useMedia('sm');
  const isMd = useMedia('md');
  const SHOW_MORE = t('label_show_more');
  const SHOW_LESS = t('label_show_less');

  return prebuiltCards &&
    Array.isArray(prebuiltCards) &&
    prebuiltCards?.length > 0 ? (
    <SectionWrapper data-test-id="prebuilt-comparison-table">
      <ContentWrapper>
        {heading && (
          <h2
            className={styles.heading}
            data-test-id="prebuilt-comparison-heading"
          >
            {heading}
          </h2>
        )}
        {subheading && (
          <p data-test-id="prebuilt-comparison-subheading">{subheading}</p>
        )}
        <div className={styles.contentBg}>
          {isSm || isMd ? (
            <PrebuiltComparisonTableSm
              showMore={showMore}
              prebuiltCards={prebuiltCards}
              currencyCode={currencyCode}
              comparisonProducts={comparisonProducts}
              enabledCardFields={enabledCardFields}
              chipsetType={chipsetType}
              region={region}
            />
          ) : (
            <div className={styles.gridWrapper}>
              <PrebuiltComparisonTableLg
                showMore={showMore}
                prebuiltCards={prebuiltCards}
                currencyCode={currencyCode}
                comparisonProducts={comparisonProducts}
                enabledCardFields={enabledCardFields}
                chipsetType={chipsetType}
                region={region}
              />
            </div>
          )}
          <Button
            type="button"
            buttonStyle="primary"
            className={styles.buttonStyles}
            onClick={() => setShowMore(!showMore)}
            dataTestId="prebuilt-comparison-button"
          >
            {showMore ? SHOW_LESS : SHOW_MORE}
          </Button>
        </div>
      </ContentWrapper>
    </SectionWrapper>
  ) : null;
};

export default memo(PrebuiltComparisonTable);
